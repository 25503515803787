import ZipCodeItem from "./ZipCodeItem";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";
import { callAdminApi } from "../API/ApiInterface";

export default function ZipCodes() {
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState("");
  const [allLeads, setAllelads] = useState([]);
  const [leadId, setSearchLeadId] = useState("");
  const navigate = useNavigate()
  const { setCheckItems, deleteZipCode, setZipCodeDeleted } = useContext(AppContext);


  const setLeadIdFxn = (leadId) => {
    setSearchLeadId(leadId);
  };


  useEffect(() => {
    fetch(process.env.REACT_APP_BASEURL + "/users/getFilterInfo?admin=1", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
      },
    })
      .then(async (result) => {
        const data = await result.json();

        if (data.code === 200) {
          const filters = data.body;

          const array = [];

          filters?.leadInfo.map((item) => {
            return array.push({ id: item.id, name: item.name });
          });
          setAllelads([...array]);
          setZipCodeDeleted(false)

        }
      })
      .catch((err) => console.log(err));
  }, []);


  useEffect(() => {
    callAdminApi(process.env.REACT_APP_BASEURL + "/zipcodes/all", "POST", {
      body: JSON.stringify({
        search: search,
        leadId: leadId,
      }),
      type: "json"
    })
      .then(async (data) => {

        if (data.code === 200) {
          const result = data.body;
          setContacts([...result.rows]);

          setCheckItems(result.rows.map((item) => item.id))
        }

        if (data.code === 401) {
          navigate("/admin/login")
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [search, leadId, deleteZipCode]);


  return (
    <div className="bodyNav row">
      <div className="col-md-10 border row mx-2  my-3">
        <div className="col-md-4">
          <select
            onChange={(e) => setLeadIdFxn(e.target.value)}
            className="form-select mt-2 p-2"
            aria-label=".form-select-lg example"
          >
            <option value="" >Select Lead</option>
            {allLeads.map((item) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <input
            type="text"
            placeholder="Search by (Zipcode,City,Area Code)"
            value={search}
            className="form-control mt-2 p-2"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>


        <div className="col-md-12 m-3">
          <div >
            <p style={{ float: "left", fontSize: "20px" }} className="float-left">Total Count: {contacts.length}</p>

          </div>
          <ZipCodeItem contacts={contacts} />
        </div>
      </div>


    </div>
  );
}
