import React, { useEffect, useState, useCallback } from "react";
import TextareaAutosize from "react-textarea-autosize";

import {
  MDBBtn,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBTextArea,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { toast } from "react-toastify";

export default function CommunityForm(props) {
  const [validClass, setValidClass] = useState(
    props && props.action === "update"
      ? "col-md-12 needs-validation zoom-80"
      : "col-md-8 needs-validation"
  );
  const [timer, setTimer] = useState(90);
  const [readonly] = useState(
    props && props.action === "update" ? true : false
  );

  const communityFormDescription = process.env.REACT_APP_COMMUNITY_FORM_DESCRIPTION || "Default description";

  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    state: "",
    zipcode: "",
    address: "",
    occupation: "",
    skills_experience: "",
    professional_field: [],
    volunteer_days: [],
    volunteer_time: [],
    commit_regular_schedule: "",
    interest_reason: "",
    physical_limitation: "",
    confidentiality_agreement: "",
    conflict_of_interest_statment: "",
    data_privacy: "",
    date: "",
    phone_number: "",
    country_code: "1",
  };
  const [inputData, setInputData] = useState(initialState);

  const professionalField = [
    { id: 1, name: "Architectural Engineer" },
    { id: 2, name: "Structural Engineer" },
    { id: 3, name: "Civil Engineer" },
    { id: 4, name: "Mechanical Engineer" },
    { id: 5, name: "Electrical Engineer" },
    { id: 6, name: "Geotechnical Engineer" },
    { id: 7, name: "Environmental Engineer" },
    { id: 8, name: "Plumbing and Wastewater Engineers" },
    { id: 9, name: "Ventilation Engineers" },
    { id: 10, name: "Acoustic Engineer" },
    { id: 11, name: "Construction Management" },
    { id: 12, name: "Construction Code and Governmental Relations" },
  ];

  const volDays = [
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
    { id: 7, name: "Sunday" },
  ];

  const volTime = [
    { id: 1, name: "Morning" },
    { id: 2, name: "Afternoon" },
    { id: 3, name: "Evening" },
  ];

  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );


  const updatePermList = (checked, module, fieldName, array) => {
    if (checked) array.push(module);
    else {
      var index = array.indexOf(module);
      if (index !== -1) {
        array.splice(index, 1);
      }
    }

    setInputData({
      ...inputData,
      [fieldName]: array,
    });
  };

  useEffect(() => {
    if (props && props.action === "update" && props.viewitem) {
      const viewitem = props.viewitem;
      const initialState = {
        firstName: viewitem.firstName,
        lastName: viewitem.lastName,
        email: viewitem.email,
        city: viewitem.city,
        state: viewitem.state,
        zipcode: viewitem.zipcode,
        address: viewitem.address,
        occupation: viewitem.occupation,
        skills_experience: viewitem.skills_experience,
        professional_field:
          typeof viewitem.professional_field === "object"
            ? viewitem.professional_field
            : [viewitem.professional_field],
        volunteer_days:
          typeof viewitem.volunteer_days === "object"
            ? viewitem.volunteer_days
            : [viewitem.volunteer_days],
        volunteer_time:
          typeof viewitem.volunteer_time === "object"
            ? viewitem.volunteer_time
            : [viewitem.volunteer_time],
        commit_regular_schedule: viewitem.commit_regular_schedule,
        interest_reason: viewitem.interest_reason,
        physical_limitation: viewitem.physical_limitation,
        confidentiality_agreement: viewitem.confidentiality_agreement,
        conflict_of_interest_statment: viewitem.conflict_of_interest_statment,
        data_privacy: viewitem.data_privacy,
        date: viewitem.date,
        phone_number: viewitem.phone_number,
        country_code: "1",
      };
      setInputData(initialState);
    }
  }, [props]);
  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const resetForm = (e) => {
    e.preventDefault();
    setInputData(initialState);
    setTimeout(function () {
      setValidClass("col-md-8  needs-validation " + Date.now());
    }, 500);
  };

  const handleSubmit = async (e) => {
    if (!(props && props.action === "update")) {
      if (!e.target.checkValidity()) {
        e.target.reportValidity();
        e.target.className += " was-validated";
        e.preventDefault();

        return;
      }
      e.preventDefault();

      fetch(process.env.REACT_APP_BASEURL + "/admin/community/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify(inputData),
      })
        .then(async (result) => {
          const data = await result.json();
          if (data.code === 200) {
            toast.success(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            resetForm(e);
          } else {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  return (
    <div className="col-md-12  row mx-2  my-3 ">
      <div className=" mx-2  col-md-10 row justify-content-center myPrintDiv">
        <form
          className={validClass}
          // style={{ zoom: props.action === "update" ? "55% !important" : "" }}
          onKeyDown={onKeyDown}
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
        >
          <MDBCardBody className="text-black my-5 d-flex flex-column justify-content-center">
            <h5 className="mb-5 my-5 fw-bold">
              {communityFormDescription}
            </h5>

            <label className="fs-5 text-start mb-2">
              <b>Personal Information</b>
            </label>
            <MDBRow>
              <MDBCol md="6">
                <MDBInput
                  wrapperClass="mb-4"
                  label="First Name"
                  size="lg"
                  name="firstName"
                  maxLength={30}
                  required
                  value={inputData.firstName}
                  onChange={onChange}
                  type="text"
                  disabled={readonly}
                />
              </MDBCol>

              <MDBCol md="6">
                <MDBInput
                  wrapperClass="mb-4"
                  label="Last Name"
                  size="lg"
                  name="lastName"
                  maxLength={30}
                  required
                  value={inputData.lastName}
                  onChange={onChange}
                  type="text"
                  disabled={readonly}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md="6">
                <MDBInput
                  wrapperClass="mb-4"
                  label="Email"
                  size="lg"
                  name="email"
                  type="email"
                  required
                  maxLength={40}
                  value={inputData.email}
                  onChange={onChange}
                  disabled={readonly}
                  autoComplete="off"
                />
              </MDBCol>

              <MDBCol
                style={{ marginLeft: "2px" }}
                className="row container"
                md="6"
              >
                <MDBInput
                  wrapperClass="col-3 text-center mb-4"
                  label="( +1 )"
                  size="lg"
                  name="country_code"
                  type="tel"
                  required
                  maxLength={3}
                  value={inputData.country_code}
                  onChange={onChange}
                  disabled={readonly}
                />

                <MDBInput
                  wrapperClass="col-9 mb-4"
                  label="Phone Number"
                  size="lg"
                  name="phone_number"
                  type="tel"
                  required
                  maxLength={11}
                  value={inputData.phone_number}
                  onChange={onChange}
                  disabled={readonly}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md="4">
                <MDBInput
                  wrapperClass="mb-4"
                  label="City"
                  size="lg"
                  name="city"
                  required
                  maxLength={30}
                  value={inputData.city}
                  onChange={onChange}
                  type="text"
                  disabled={readonly}
                />
              </MDBCol>

              <MDBCol md="4">
                <MDBInput
                  wrapperClass="mb-4"
                  label="State"
                  size="lg"
                  name="state"
                  required
                  maxLength={30}
                  value={inputData.state}
                  onChange={onChange}
                  type="text"
                  disabled={readonly}
                />
              </MDBCol>
              <MDBCol md="4">
                <MDBInput
                  wrapperClass="mb-4"
                  label="Zip Code"
                  size="lg"
                  name="zipcode"
                  required
                  type="text"
                  maxLength={8}
                  value={inputData.zipcode}
                  onChange={onChange}
                  disabled={readonly}
                />
              </MDBCol>
            </MDBRow>

            <MDBInput
              wrapperClass="mb-4"
              label="Address"
              size="lg"
              name="address"
              type="text"
              maxLength={256}
              value={inputData.address}
              onChange={onChange}
              required
              disabled={readonly}
            />

            <div className="d-md-flex mt-2 justify-content-start align-items-center mb-1  row">
              <label className="fs-5 text-start mb-2">
                <b>Professional Background</b>
              </label>
              <MDBCol md="12">
                <label
                  className="text-start"
                  style={{
                    display: inputData.occupation?.length > 0 ? "block" : "none",
                    color: "#627384",
                  }}
                >
                  Occupation/Profession (The character limit should fall within
                  the range of 100 to 5000)
                </label>
                <TextareaAutosize
                  className="mb-4 mt-3 col-md-12 p-2 form-control"
                  placeholder="Occupation/Profession (The character limit should fall within the range of 100 to 5000)"
                  name="occupation"
                  // rows={getTextAreaRows(inputData.occupation)}
                  required
                  minLength="100"
                  minRows={2}
                  disabled={readonly}
                  maxLength={5000}
                  value={inputData.occupation}
                  onChange={onChange}
                />
              </MDBCol>

              <MDBCol md="12 text-start">
                <label>
                  Please describe any skills or experience in construction,
                  engineering, design, project management, or any other relevant
                  area.
                </label>

                <div className="col-md-12">
                  <label
                    className="text-start mt-1"
                    style={{
                      display:
                        inputData.skills_experience?.length > 0
                          ? "block"
                          : "none",
                      color: "#627384",
                    }}
                  >
                    Relevant Skills and Experience (The character limit should
                    fall within the range of 300 to 5000.)
                  </label>
                  <TextareaAutosize
                    className="mb-4 mt-3 col-md-12 form-control"
                    placeholder="Relevant Skills and Experience (The character limit should fall within the range of 300 to 5000.)"
                    // size="lg"
                    name="skills_experience"
                    required
                    minRows={2}
                    minLength="300"
                    disabled={readonly}
                    maxLength={5000}
                    value={inputData.skills_experience}
                    onChange={onChange}
                  />
                </div>
              </MDBCol>
            </div>

            <div>
              <MDBRow className="text-start mx-1 mt-2 mb-2">
                <MDBCol md="12">
                  <label className="mb-2">
                    <b>Select Profession Field:</b>
                  </label>
                  {professionalField.map((item) => (
                    <div className="col-md-6 mt-2">
                      <input
                        checked={
                          inputData.professional_field.includes(item.name)
                            ? "checked"
                            : ""
                        }
                        onChange={(e) =>
                          updatePermList(
                            e.target.checked,
                            item.name,
                            "professinal_field",
                            inputData.professional_field
                          )
                        }
                        id={item.id}
                        className="form-check-input  m-2"
                        type="checkbox"
                        required={
                          inputData.professional_field.length === 0
                            ? true
                            : false
                        }
                        disabled={readonly}
                      />
                      {item?.name}
                    </div>
                  ))}
                </MDBCol>
              </MDBRow>

              <MDBRow className="text-start mx-1 mt-4 mb-2">
                <hr></hr>

                <label className="fs-5 text-start mb-2">
                  <b>Availability</b>
                </label>
                <MDBCol md="12">
                  <label className="mb-2">
                    <b>Preferred Volunteer Days</b>
                  </label>
                  {volDays.map((item) => (
                    <div className="col-md-6 mt-2">
                      <input
                        checked={
                          inputData.volunteer_days.includes(item.name)
                            ? "checked"
                            : ""
                        }
                        onChange={(e) =>
                          updatePermList(
                            e.target.checked,
                            item.name,
                            "volunteer_days",
                            inputData.volunteer_days
                          )
                        }
                        id={item.id}
                        className="form-check-input  m-2"
                        type="checkbox"
                        required={
                          inputData.volunteer_days.length === 0 ? true : false
                        }
                        disabled={readonly}
                      />
                      {item?.name}
                    </div>
                  ))}
                </MDBCol>
              </MDBRow>

              <MDBRow className="text-start mx-1 mt-4 mb-2">
                <MDBCol md="12">
                  <label className="mb-2">
                    <b>Preferred Volunteer Time</b>
                  </label>
                  {volTime.map((item) => (
                    <div className="col-md-6 mt-2">
                      <input
                        checked={
                          inputData.volunteer_time.includes(item.name)
                            ? "checked"
                            : ""
                        }
                        onChange={(e) =>
                          updatePermList(
                            e.target.checked,
                            item.name,
                            "volunteer_time",
                            inputData.volunteer_time
                          )
                        }
                        id={item.id}
                        disabled={readonly}
                        className="form-check-input  m-2"
                        type="checkbox"
                        required={
                          inputData.volunteer_time.length === 0 ? true : false
                        }
                      />
                      {item?.name}
                    </div>
                  ))}
                </MDBCol>
              </MDBRow>

              <MDBRow className="text-start mx-1 mt-4 mb-2">
                <MDBCol md="12">
                  <label className="mb-2">
                    <b>Are you able to commit to a regular schedule?</b>
                  </label>

                  <div className="col-md-6">
                    <input
                      checked={
                        inputData.commit_regular_schedule === 1 ? "checked" : ""
                      }
                      disabled={readonly}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          commit_regular_schedule: 1,
                        })
                      }
                      name="commit_regular_schedule"
                      className="form-check-input  m-2"
                      type="radio"
                      required={
                        inputData.commit_regular_schedule === "" ? true : false
                      }
                    />
                    <label className="mt-1">Yes</label>
                  </div>
                  <div className="col-md-6 mt-2">
                    <input
                      checked={
                        inputData.commit_regular_schedule === 0 ? "checked" : ""
                      }
                      disabled={readonly}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          commit_regular_schedule: 0,
                        })
                      }
                      name="commit_regular_schedule"
                      className="form-check-input  m-2"
                      type="radio"
                      required={
                        inputData.commit_regular_schedule === "" ? true : false
                      }
                    />
                    <label className="mt-1">No</label>
                  </div>
                </MDBCol>
              </MDBRow>
            </div>

            <hr></hr>
            <div className=" mx-1  d-md-flex ustify-content-start align-items-center mt-2 mb-1 row ">
              <label className="fs-5 text-start mb-2">
                <b>Interest</b>
              </label>
              <MDBCol md="12 mt-2 text-start">
                <label>
                  Why are you interested in participating in the building of the
                  Sri Pasupatinath and Shyambhunath temple and stupa?
                </label>

                <label
                  className="text-start  mt-1"
                  style={{
                    display:
                      inputData.interest_reason?.length > 0 ? "block" : "none",
                    color: "#627384",
                  }}
                >
                  Reason of interest (The character limit should fall within the
                  range of 300 to 5000.)
                </label>
                <TextareaAutosize
                  className="mb-4 mt-3 col-md-12 form-control"
                  placeholder="Reason of interest (The character limit should fall within the range of 300 to 5000.)"
                  size="lg"
                  name="interest_reason"
                  required
                  minRows={2}
                  minLength="300"
                  disabled={readonly}
                  maxLength={5000}
                  value={inputData.interest_reason}
                  onChange={onChange}
                />
              </MDBCol>
            </div>
            <hr></hr>

            <div className=" mx-1 d-md-flex ustify-content-start align-items-center mt-2mb-1 row ">
              <label className="fs-5 text-start mb-2">
                <b>Additional Information</b>
              </label>
              <MDBCol md="12 mt-2 text-start">
                <label>
                  Do you have any physical limitations or health considerations
                  that the project coordinators should be aware of? (Optional)
                </label>



                <label
                  className="text-start  mt-1"
                  style={{
                    display:
                      inputData.physical_limitation?.length > 0
                        ? "block"
                        : "none",
                    color: "#627384",
                  }}
                >
                  Physical limitations or Health considerations (Maximum 5000
                  characters allowed){" "}
                </label>
                <TextareaAutosize
                  className="mb-4 mt-3 col-md-12 form-control"
                  placeholder="Physical limitations or Health considerations (Maximum 5000 characters allowed)"
                  size="lg"
                  name="physical_limitation"
                  minRows={2}
                  maxLength={5000}
                  disabled={readonly}
                  value={inputData.physical_limitation}
                  onChange={onChange}
                />
              </MDBCol>
            </div>
            <hr></hr>
            {/* <div className=" mx-1 d-md-flex ustify-content-start align-items-center mt-2 mb-1 row "> */}
            <MDBRow className=" mx-1 d-md-flex justify-content-start align-items-center mt-2 mb-1">
              <label className="fs-5 text-start mb-2">
                <b>Consent and Privacy Policy</b>
              </label>
              <MDBCol md="12 mt-4 text-start">
                <label>
                  <b>Confidentiality Agreement</b>
                </label>
                <div className="col-md-12 mt-2 text-start">
                  <input
                    checked={
                      inputData.confidentiality_agreement === 1 ? "checked" : ""
                    }
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        confidentiality_agreement: Number(e.target.checked),
                      })
                    }
                    disabled={readonly}
                    className="form-check-input  m-2"
                    type="checkbox"
                    required
                  />
                  I agree that any information presented to me as a part of my
                  involvement with the Sri Pasupatinath and Shyambhunath temple
                  and stupa projects will be kept confidential. I will not
                  disclose any sensitive information outside of the necessary
                  contexts within the Nepali Cultural and Spiritual Center
                  (NCSC) and the Planning, Design, and Construction Committee
                  (PDC).{" "}
                </div>
              </MDBCol>

              <MDBCol md="12 mt-4 text-start">
                <label>
                  <b>Conflict of Interest Statement</b>
                </label>
                <div className="col-md-12 mt-2 text-start">
                  <input
                    checked={
                      inputData.conflict_of_interest_statment === 1
                        ? "checked"
                        : ""
                    }
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        conflict_of_interest_statment: Number(e.target.checked),
                      })
                    }
                    className="form-check-input  m-2"
                    type="checkbox"
                    disabled={readonly}
                    required
                  />
                  I declare that I will not serve as a vendor or sub-vendor in
                  any trade with the NCSC while I am a member of this committee.
                  I understand that such an involvement would constitute a
                  conflict of interest and could compromise the integrity of
                  both the NCSC and the projects at hand
                </div>
              </MDBCol>

              <MDBCol md="12 mt-4 text-start">
                <label>
                  <b>Data Privacy</b>
                </label>
                <div className="col-md-12 mt-2 text-start">
                  <input
                    checked={inputData.data_privacy === 1 ? "checked" : ""}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        data_privacy: Number(e.target.checked),
                      })
                    }
                    className="form-check-input font-16 m-2"
                    type="checkbox"
                    disabled={readonly}
                    required
                  />
                  I acknowledge that the NCSC and PDC will handle my personal
                  information with strict confidentiality. This information will
                  be used solely for the purpose of my involvement in the temple
                  and stupa projects and will not be shared with third parties
                  without my explicit consent
                </div>
              </MDBCol>
            </MDBRow>
            <div>
              <MDBRow className="mx-2 mt-5">
                <MDBCol md="6">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Date"
                    size="lg"
                    name="date"
                    maxLength={30}
                    required
                    value={inputData.date}
                    onChange={onChange}
                    type="date"
                    disabled={readonly}
                    min={new Date().toISOString().split("T")[0]}
                  />
                </MDBCol>
              </MDBRow>
            </div>

            <div
              style={{
                display: props && props.action === "update" ? "none" : "block",
              }}
              className="justify-content-end pt-3 pb-2"
            >
              <MDBBtn onClick={resetForm} color="secondary" size="m">
                Reset all
              </MDBBtn>
              <MDBBtn
                className="ms-1 px-4 pr-4"
                style={{ backgroundColor: "#556b2f" }}
                size="m"
              >
                Join now
              </MDBBtn>
            </div>
          </MDBCardBody>
        </form>
      </div>
      <div></div>
    </div>
  );
}
