import React from "react";

function Feedback() {
    return (
        <div className="container mt-4">
            <h2 className="text-center">Feedback Form</h2>
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSd_qiLjrlOY2yMVEuKP1-g8LVxHflPRuBYLlMknqO3Yg4ou3g/viewform?embedded=true"
                width="100%"
                height="1200"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                title="Feedback Form"
            >
                Loading…
            </iframe>
        </div>
    );
}

export default Feedback;
