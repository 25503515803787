import React from "react";
import { useState, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Card } from "react-bootstrap";
import { useEffect } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { callAdminApi } from "./API/ApiInterface";
import { AppContext } from "./AppContext";
import { useSelector } from "react-redux";
const formHeader = process.env.REACT_APP_FORM_HEADER || "Default Form Header";
const communityFormHeader = process.env.REACT_APP_COMMUNITY_FORM_HEADER || "Default Community Form Header";
const showMainForm = process.env.REACT_APP_SHOW_MAIN_FORM === "true";
const showCommunityForm = process.env.REACT_APP_SHOW_COMMUNITY_FORM === "true";
const showUpdateForm = process.env.REACT_APP_SHOW_UPDATE_FORM === "true";

const NavbarTop = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const { setAllModules, setIsLoggedIn } = useContext(AppContext);
  const [modules, setModules] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const profileInfo = useSelector((state) => state.loginInfo.data);

  useEffect(() => {
    if (!showCommunityForm && location.pathname === "/community") {
      navigate("/");
    }
    if (!showUpdateForm && location.pathname === "/updateUser") {
      navigate("/");
    }
  }, [showCommunityForm, showUpdateForm, location.pathname, navigate]);


  useEffect(() => {
    if (
      location.pathname.includes("admin") &&
      !location.pathname.includes("reset")
    ) {
      const tokenData = localStorage.getItem("token");

      if (!(tokenData && tokenData !== "null" && tokenData !== "undefined")) {
        return navigate("/admin/login");
      }
    }
  }, []); //location

  const shouldShowNavbar = () => {
    const { pathname } = location;
    return !["/unsubscribe", "/signup", "/get-community-form"].includes(
      pathname
    );
  };

  const checkValidModule = (link) => {
    const findItem = modules.find((item) => item.module?.link === link);
    if (findItem && location.pathname.includes("admin")) return true;
    else return false;
  };

  const checkLogin = () => {
    const tokenData = localStorage.getItem("token");

    if ((tokenData && tokenData === "null") || tokenData === "undefined") {
      return false;
    } else return true;
  };
  useEffect(() => {
    if (location.pathname.includes("admin")) {
      callAdminApi(
        process.env.REACT_APP_BASEURL + "/admin/fetchPermissions?type=link",
        "GET",
        { type: "json", body: null, auth: true }
      )
        .then(async (data) => {
          if (data.code === 200) {
            setModules(data.body);
            setAllModules(data.body);
          }
        })
        .catch((e) => {
          setModules([]);
          setAllModules([]);
          console.log(e);
        });
    }
  }, []); //location.pathname

  const handleLogout = () => {
    localStorage.setItem("token", null);
    setIsLoggedIn(false);
    setTimeout(function () {
      navigate("/admin/login");
    }, 700);
  };

  const handleClick = () => {
    setExpanded(false);
  };
  return (
    shouldShowNavbar() && (
      <Navbar
        expand="lg"
        expanded={expanded}
        bg="dark"
        data-bs-theme="dark"
        className="navbar-dark navbar-full bg-body-tertiary fixed-top "
      >
        <Container>
          <Navbar.Brand>
            <Link className="navbar-brand" to="/home">
              <img alt="logo" src="../logo.jpg" height={50} />{" "}
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={() => setExpanded(expanded ? false : "expanded")}
            aria-controls="responsive-navbar-nav"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                as={Link}
                style={{
                  display: checkValidModule("/admin/scanner")
                    ? "inline"
                    : "none",
                }}
                onClick={handleClick}
                to="/admin/scanner"
              >
                <span className="fs-5">Verify Registration</span>
              </Nav.Link>

              <Nav.Link
                style={{
                  display: checkValidModule("/admin/home") ? "inline" : "none",
                }}
                as={Link}
                onClick={handleClick}
                to="/admin/home"
              >
                <span className="fs-5">Dashboard</span>
              </Nav.Link>

              <Nav.Link
                as={Link}
                onClick={handleClick}
                style={{
                  display: checkValidModule("/admin/status")
                    ? "inline"
                    : "none",
                }}
                to="/admin/status"
              >
                <span className="fs-5">Member Status</span>
              </Nav.Link>

              <NavDropdown
                style={{
                  display: checkValidModule("/admin/users") ? "inline" : "none",
                }}
                title="Users"
                className="fs-5"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  onClick={handleClick}
                  key="user_1"
                  as={Link}
                  to="admin/users"
                >
                  Users List
                </NavDropdown.Item>

                <NavDropdown.Item
                  key="user_2"
                  as={Link}
                  onClick={handleClick}
                  to="admin/user/permission"
                >
                  Edit Permissions
                </NavDropdown.Item>
              </NavDropdown>

              {showMainForm && (
                <Nav.Link
                  style={{
                    display: location.pathname.includes("admin")
                      ? "none"
                      : "inline",
                  }}
                  className="text-warning"
                  as={Link}
                  onClick={handleClick}
                  to="/"
                >
                  <span
                    className="fs-5"
                    style={{
                      color: location.pathname !== "/" ? "#c4c06e" : "#0d6efd",
                    }}
                  >
                    {formHeader}
                  </span>
                </Nav.Link>)}

              {showCommunityForm && (
                <Nav.Link
                  style={{
                    display: location.pathname.includes("admin")
                      ? "none"
                      : "inline",
                  }}
                  onClick={handleClick}
                  className="text-info"
                  as={Link}
                  to="/community"
                >
                  <span
                    className="fs-5"
                    style={{
                      color: !location.pathname.includes("/community")
                        ? "#c4c06e"
                        : "#0d6efd",
                    }}
                  >
                    {communityFormHeader}
                  </span>
                </Nav.Link>
              )}

              {showUpdateForm && (
                <Nav.Link
                  style={{
                    display: location.pathname.includes("admin")
                      ? "none"
                      : "inline",
                  }}
                  onClick={handleClick}
                  className="text-success"
                  as={Link}
                  to="/updateUser"
                >
                  <span
                    className="fs-5"
                    style={{
                      color: !location.pathname.includes("/updateUser")
                        ? "#c4c06e"
                        : "#0d6efd",
                    }}
                  >
                    Update Details!
                  </span>
                </Nav.Link>
              )}

              <Nav.Link
                style={{
                  display: location.pathname.includes("admin") ? "none" : "inline",
                }}
                as={Link}
                to="/feedback"
                className="text-success"
              >
                <span
                  className="fs-5"
                  style={{
                    color: !location.pathname.includes("/feedback") ? "#c4c06e" : "#0d6efd",
                  }}
                >
                  Student Support Feedback
                </span>
              </Nav.Link>

              <Nav.Link
                style={{
                  display:
                    location.pathname.includes("admin") && !checkLogin()
                      ? "inline"
                      : "none",
                }}
                onClick={handleClick}
                className="text-primary"
                as={Link}
                to="/admin/login"
              >
                <span className="fs-5">Admin Login</span>
              </Nav.Link>

              <NavDropdown
                style={{
                  display:
                    location.pathname.includes("admin") && checkLogin()
                      ? "inline"
                      : "none",
                }}
                title="Actions"
                className="fs-5"
                id="basic-nav-dropdown"
              >
                {modules.map((item) => (
                  <NavDropdown.Item
                    key={item.id}
                    as={Link}
                    style={{
                      display: item.module?.showInAction ? "block" : "none",
                    }}
                    onClick={handleClick}
                    to={item.module?.link}
                  >
                    {item.module?.moduleName}
                  </NavDropdown.Item>

                  // <NavDropdown.Item as={Link} to="/admin/access">
                  //   Control Area
                  // </NavDropdown.Item>
                  // <NavDropdown.Item as={Link} to="/admin/zipcodes">
                  //  All Zipcodes
                  // </NavDropdown.Item>
                  // <NavDropdown.Item as={Link} to="/admin/users">
                  //   Users Management
                  // </NavDropdown.Item>

                  // <NavDropdown.Item as={Link} to="/admin/subAdmins">
                  //   Sub Admins
                  // </NavDropdown.Item>

                  // <NavDropdown.Item as={Link} to="/admin/email">
                  //   Send Email or SMS
                  // </NavDropdown.Item>
                ))}
              </NavDropdown>
            </Nav>
            <Nav
              className="mr-4"
              style={{
                display:
                  location.pathname.includes("admin") && checkLogin()
                    ? "inline"
                    : "none",
                marginLeft: "20%",
              }}
            >
              <NavDropdown
                title={profileInfo.firstName + ` ` + profileInfo.lastName}
                className="fs-5 d-flex text-white float-right"
                id="basic-nav-dropdown"
              >
                <Card
                  bg="light"
                  text="dark"
                  style={{ width: "18rem" }}
                  className="mb-2"
                >
                  <Card.Header className="text-start">
                    <i className="fa fa-envelope fs-5"></i>
                    <span className="mx-2 fs-5">{profileInfo.email}</span>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <i className="fa fa-mobile fs-5"></i>{" "}
                      <span className="mx-1 fs-5">
                        +
                        {profileInfo.countryCode +
                          ` ` +
                          profileInfo.phoneNumber}
                      </span>{" "}
                      <br />
                      <i className="fa fa-user fs-5"></i>{" "}
                      <span className="mx-1 fs-5">
                        {profileInfo.role === 1 ? "Sub-Admin" : "Admin"}
                      </span>
                    </Card.Text>
                    <button
                      className="btn btn-danger btn-xs  "
                      onClick={handleLogout}
                    >
                      <span className="fs-5">Logout</span>
                    </button>
                  </Card.Body>
                </Card>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  );
};

export default NavbarTop;
