import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import QrReader from "modern-react-qr-reader";
import { toast } from "react-toastify";
import Modal from "react-modal";
import Otp from "./Otp";
import { callAdminApi } from "../API/ApiInterface";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
import { customStyles } from "../CustomStyle";
export default function Scanner() {
  const [data, setData] = useState("No result");
  const [userId, setuserId] = useState("");
  const [scanType, setScantype] = useState(1);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [legacyMode, setLegecyMode] = useState(false);
  const [selectedCount, setSeelctedCount] = useState(1);
  const [mobileOption] = useState(0);
  const { checkPermission } = useContext(AppContext);
  let navigate = useNavigate();

  const reader = useRef(null);

  const previewStyle = {
    width: 480,
    margin: "0 auto",
  };

  useEffect(() => {
    try {
      navigator.mediaDevices
        ?.getUserMedia({ video: true, audio: true })
        .catch((e) => {
          console.log(e);
        });

      checkPermission(10, 2).then((result) => {
        if (!result) return navigate("/admin/login");
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (reader.current && legacyMode === true && mobileOption === 0) {
      reader.current.openImageDialog();
    }
  }, [legacyMode, mobileOption]);

  const handleError = (error) => {
    console.log(error);
  };

  const openImageDialog = (e) => {
    setLegecyMode(true);
  };

  const displayContent = (data) => {
    console.log(data, ">>>>>>>>>>");
    if (data) {
      try {
        //setData(result?.text);
        //const data=result?.text
        setData(data);
        setScantype(data.scantype ? data.scanType : 1);
        setuserId(data.id);
        setSeelctedCount(data.selectedCount ? data.selectedCount : 1);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    }
  };

  const getuserDetail = (qrcode) => {
    if (qrcode) {
      fetch(process.env.REACT_APP_BASEURL + "/users/checkUserDetail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
        body: JSON.stringify({ phoneNumber: qrcode }),
      })
        .then(async (result) => {
          const data = await result.json();
          if (data.code === 200) {
            const rData = data.body;

            let difference =
              parseInt(rData.householdSize) - parseInt(rData.confirmationCount);

            console.log(`difference\n\n${difference}`);
            if (difference === 0) {
              toast.error(
                "This user has exceeded the verification limit; please wait 24 hours to reactivate the count",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
            } else {
              const senddata = {
                id: rData.id,
                name: rData.firstName + " " + rData.lastName,
                contact: rData.phoneNumber,
                email: rData.email,
                zipCode: rData.zipCode,
                address: rData.address,
                scantype: 1,
                count: 1,
              };
              setuserId(rData.id);
              displayContent(senddata);
            }
          } else {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  useEffect(() => {
    if (userId !== "") {
      updateCountApi();
    }
  }, [userId]);

  const resetCamera = () => {
    setLegecyMode(false);
  };

  const updateCountApi = useCallback(() => {
    if (userId !== "") {
      callAdminApi(
        process.env.REACT_APP_BASEURL + "/admin/scan/updateConfirmationCount",
        "POST",
        {
          type: "json",
          auth: true,
          body: JSON.stringify({
            id: userId,
            scantype: scanType,
            count: selectedCount,
          }),
        }
      )
        .then(async (data) => {
          setuserId("");
          if (data.code === 200) {
            toast.success(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setScantype(1);
            setIsOpen(true);
            setTimeout(function () {
              resetCamera();
              setIsOpen(false);
            }, 15000);
          } else {
            setTimeout(function () {
              resetCamera();
            }, 700);
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }, [userId]);
  return (
    <div className="bodyNav row mx-2">
      <div className="row justify-content-center">
        <div className="col-md-8 border border-red  justify-content-center mx-2 text-center row my-3">
          <Otp displayScannedInfo={displayContent}></Otp>
        </div>
        <div className="col-md-8 border border-red  justify-content-center mx-2 text-center row my-3">
          <div className="col-md-10 mx-2 row my-3">
            <h3>Scan QR Code</h3>
            <QrReader
              ref={reader}
              className="border"
              delay={1000}
              facingMode={"environment"}
              onScan={(result) => getuserDetail(result)}
              onError={(error) => {
                handleError(error);
              }}
              legacyMode={legacyMode}
              style={previewStyle}
            />
          </div>
          <div className="col-md-10 mx-2 row my-3">
            <button
              type="button"
              style={{ margin: "0 auto", width: "250px" }}
              className="btn btn-success"
              onClick={openImageDialog}
            >
              Pick image from gallery
            </button>
          </div>

          <div className="col-md-10 mx-2 row my-3">
            <button
              type="button"
              style={{ margin: "0 auto", width: "200px" }}
              className="btn btn-info col-md-10"
              onClick={() => setLegecyMode(false)}
            >
              Reset Camera
            </button>
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          // onAfterOpen={afterOpenModal}
          style={customStyles}
          appElement={document.getElementById("root")}
          contentLabel="Example Modal"
        >
          <div
            id="customers"
            className="infowindow overflow-auto"
            style={{ height: "400px" }}
          >
            <h3>Scanned Information</h3>
            <table className="mt-3 ">
              <th>Heading</th>
              <th>Info</th>
              <tr>
                <td>Name</td>
                <td>{data?.name}</td>
              </tr>

              <tr>
                <td>Email</td>
                <td>{data?.email}</td>
              </tr>

              <tr>
                <td>Phone Number</td>
                <td>{data?.contact}</td>
              </tr>

              <tr>
                <td>ZipCode</td>
                <td>{data?.zipCode}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>{data?.address}</td>
              </tr>
            </table>
          </div>

          <button
            onClick={() => {
              setIsOpen(false);
              setLegecyMode(false);
            }}
            className="btn btn-info float-right"
          >
            Scan Again
          </button>
        </Modal>
      </div>
    </div>
  );
}
